<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary">投屏</en-button>
    </template>

    <template #default="{ height }">
      <en-form :model="form.data" :ref="setRef('form')" label-width="80px" label-position="left">
        <en-form-item label="日期" width="100px">
          <en-date-picker v-model:start="form.data.startDate" v-model:end="form.data.endDate" type="daterange"
            value-format="YYYY-MM-DD"></en-date-picker>
        </en-form-item>
        <en-form-item label="拼团类型">
          <select-maintain
            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MGPTYPE']) }"
            :props="{ label: 'message', value: 'code' }" v-model="form.data.mallGroupPurchaseTypeCode"></select-maintain>
        </en-form-item>
        <en-form-item label="拼团名称">
          <en-input v-model="form.data.mallGroupPurchaseTypeName"></en-input>
        </en-form-item>
        <en-form-item label="大标题" prop="title">
          <en-input v-model="form.data.title" label-width="100px"></en-input>
        </en-form-item>
        <en-form-item label="小标题" prop="subtitle">
          <en-input v-model="form.data.subtitle"></en-input>
        </en-form-item>
        <en-form-item label="选择区域" prop="regions">
          <select-maintain :ajax="{ action: 'GET /enocloud/common/region' }" :props="{ label: 'name', value: '' }"
            clearable multiple v-model="form.data.regions" value-key="id"></select-maintain>
        </en-form-item>
        <en-form-item label="中心数据" prop="type">
          <select-maintain
            :options="[{ message: '总浏览量', field: 'visitCount' }, { message: '新集客量', field: 'newCustomerCount' }, { message: '下单数量', field: 'dealCount' }, { message: '总金额', field: 'dealAmount' }]"
            :props="{ label: 'message', value: '' }" clearable v-model="form.data.type"
            value-key="field"></select-maintain>
        </en-form-item>
        <en-form-item label="中心倍数">
          <en-input v-model="form.data.centerMultiple"></en-input>
        </en-form-item>
        <en-form-item label="单位">
          <en-input v-model="form.data.unit"></en-input>
        </en-form-item>

        <en-form-item label="左侧标题" prop="leftLabel">
          <en-input v-model="form.data.leftLabel"></en-input>
        </en-form-item>

        <en-form-item label="右侧标题" prop="rightLabel">
          <en-input v-model="form.data.rightLabel"></en-input>
        </en-form-item>
        <en-form-item label="右侧数据" prop="rightType">
          <select-maintain
            :options="[{ message: '总浏览量', field: 'visitCount' }, { message: '新集客量', field: 'newCustomerCount' }, { message: '下单数量', field: 'dealCount' }, { message: '总金额', field: 'dealAmount' }]"
            :props="{ label: 'message', value: '' }" clearable v-model="form.data.rightType"
            value-key="field"></select-maintain>
        </en-form-item>
        <en-form-item label="右侧倍数">
          <en-input v-model="form.data.rightMultiple"></en-input>
        </en-form-item>
      </en-form>
    </template>

  </view-item-setting>
</template>

<script lang="ts">

export default factory({
  config: {
    children: {
      form: {
        is: 'form',
        data: {
          startDate: '',
          endDate: '',
          mallGroupPurchaseTypeCode: '',
          mallGroupPurchaseTypeName: '',
          title: '',
          subtitle: '',
          leftLabel: '',
          rightLabel: '',
          regions: [],
          type: {},
          unit: '',
          centerMultiple: '',
          rightType: {},
          rightMultiple: ''
        }
      }
    }
  },

  mounted() {
  }
})
</script>
